.requestDetailContainer {
  display: flex;
  flex-direction: column; /* Align children in a column */
  align-items: center; /* Center children horizontally */
  padding: 20px;
}
  .requestDetailCard {
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px; /* Maximum width of the card */
    margin: auto;
    padding: 20px;
    background-color: white;
    text-align: left; /* Right-align text */
    margin-bottom: 20px; /* Add space between card and buttons */
    position: relative; /* This will be the anchor for the absolute positioning of the timestamp */
  }

  .timestampContainer {
    position: absolute;
    top: 10px; /* Adjust as necessary for your layout */
    right: 10px; /* Adjust as necessary for your layout */
    background-color: #f3f3f3; /* Optional: if you want a different background */
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .requestDetailContent {
    margin-top: 20px;
  }
  
  .requestDetailContent h1,
  .requestDetailContent h3,
  .requestDetailContent p {
    margin: 10px 0; /* Add some spacing between text elements */
    word-break: break-word;
  }
  

  .buttonsContainer {
    display: flex;
  }
  
  .deleteButton {
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .backButton {
    background-color: blue;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
  }