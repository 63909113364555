/* App.css */

/* Center the message in the middle of the screen */
.desktop-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Make the message take up the entire vertical height */
  background-color: #f2f2f2; /* Background color for the message */
}

/* Style the message text */
.desktop-message p {
  font-size: 24px;
  text-align: center;
  padding: 20px;
  border: 2px solid #333; /* Add a border for emphasis */
  background-color: #fff; /* Background color for the message text */
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
}
