.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height of the viewport */
    padding: 50px; /* Space around the container */
    background-color: #f8f9fa; /* Light background for the entire page */
}

/* Assuming you have a similar banner or image for the sign-up page */
.signup-banner {
    flex: 1;
    /* Add styles for your left-side image or illustration here */
    /* background: url('path-to-your-signup-image.jpg') no-repeat center center; */
    background-size: cover;
}

.signup-form {
    flex: 1;
    max-width: 400px; /* Maximum width of the form */
    padding: 20px;
    background-color: white; /* White background for the form */
    border-radius: 8px; /* Rounded corners for the form */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Some shadow for depth */
    margin-left: 20px; /* Separate form from the banner */
}



.signup-small-logo {
    display: none;
    max-width: 100px; /* Adjust the size of the smaller logo as needed */
    margin: 0 auto; /* This will center the logo */
    margin-bottom: 20px; /* Space between the logo and the form title */
}

@media (max-width: 906px) {
.signup-banner {
    display: none; /* This will hide the banner */
}

.signup-form {
    width: 100%; /* Let the form take the full width of the container */
    max-width: 400px; /* Set a max width for smaller screens */
    margin: 0 auto; /* Center the form on smaller screens */
    height: auto; /* Reset to default height */
}

/* Only show the small logo when the media query is active */
.signup-small-logo {
    display: block; /* This will show the small logo */
}
}
