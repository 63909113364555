.event-list {
    display: flex;
    flex-direction: column;
    align-items: center; /* center cards horizontally */
    /* gap: 20px; /* space between cards */
    /* padding: 20px 0; padding top and bottom of the list */
    height:1000px
}
  
.event-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding-top: 10px; */
    padding-left: 10px;      
    padding-right: 10px;
    height: 50px;
    background: linear-gradient(to bottom, #f7f8fa, #e7e9ec); /* subtle gradient background */
    border-radius: 20px; /* rounded corners */
    box-shadow: 5px 5px 15px #aaa, -5px -5px 15px #fff; /* Shadow effect for 3D look */
    width: 90%; /* width less than 100% of the container for space on the sides */
    margin: 0 auto; /* center the card horizontally */
    margin-bottom: 10px; /* space between cards */
}

.event-title {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align title to the start */
    /* Add padding if needed */
}

.event-time-type {
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Align time and type to the end (right side) */
    justify-content: center; /* Center time and type vertically */
    /* Add padding if needed */
}

/* .event-class-type-delete{ */
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%; */
/* } */

.event-card h3 {
    margin: 0; /* Adjust as needed */
    font-size: 1.2em; /* Increase font size for title */
    line-height: 14px;
    margin-right: 15px; /* Adds some space between title and time */
}

.event-card p {
    font-size: 0.9em; /* Smaller font for event details */
    margin-top: auto;
    margin-bottom: 3px;
    margin-left: auto;
}

.event-card .event-time {
    margin-bottom: 0; /* Remove margin to allow class type to be closer */
}

.event-card .event-class-type {
    font-size: 0.8em; /* Slightly smaller font for class type */
    color: #666; /* Dimmed color for less emphasis */
    margin-top: 0; /* Aligns with the time */
}

.card-icon {
    margin-right: 7px;    
    font-size: 1.2em;
    color: #333;
}

.divider {
    height: 60%;             
    width: 1px;              
    background-color: #000; 
    margin-right: 10px;      
}

.event-content {
    flex-grow: 1; 
    display: flex;
    flex-direction: column; /* Stack children vertically */
    justify-content: center; /* Center children vertically within the container */
    align-items: flex-start; /* Align children to the start of the flex container (left side) */
}



.event-card .event-time,
.event-card .event-class-type {
    margin: 0; /* Adjust as needed */
    /* Other styles remain the same */
}