.programmingUploadPageContainer {
    padding: 20px;
    background: #f4f7f6;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.title {
    margin-bottom: 40px;
    text-align: center;
    margin-top: 20px; /* Adjust as necessary to fit your design */
}

.uploadCard {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fileInput {
    display: none;
}

.fileLabel {
    display: inline-block;
    padding: 10px 20px;
    background: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-bottom: 10px;
}

.fileLabel:hover {
    background: #0056b3;
}

.uploadButton {
    display: inline-block;
    padding: 8px 16px; /* Adjusted padding for a smaller button */
    background: #6c757d; /* Changed to a gray color */
    /* padding: 10px 20px;
    background: #28a745; */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.uploadButton:disabled {
    background: #cccccc;
    cursor: not-allowed;
}

.uploadButton:hover:not(:disabled) {
    background: #218838;
}

.errorText {
    color: red;
    margin-top: 10px;
}

.successText {
    color: green;
    margin-top: 10px;
}
