
.userRequestPageContainer {
  padding: 20px;
  background: #f4f7f6;
  min-height: 100vh;
  /* max-width: 98%; */
  max-width: 100%;
}



/* Media queries */
/* @media (max-width: 1765px) {
    .userRequestPageContainer {
      max-width: 97%;
    }
  }
  
  @media (max-width: 1323px) {
    .userRequestPageContainer {
      max-width: 96%;
    }
  }
  
  @media (max-width: 969px) {
    .userRequestPageContainer {
      max-width: 95%;
    }
  }
  
  @media (max-width: 777px) {
    .userRequestPageContainer {
      max-width: 94%;
    }
  }
  
  @media (max-width: 627px) {
    .userRequestPageContainer {
      max-width: 93%;
    }
  }
  
  @media (max-width: 527px) {
    .userRequestPageContainer {
      max-width: 92%;
    }
  }
  
  @media (max-width: 465px) {
    .userRequestPageContainer {
      max-width: 90%;
    }
  }
  
  @media (max-width: 373px) {
    .userRequestPageContainer {
      max-width: 88%;
    }
  }
  
  @media (max-width: 313px) {
    .userRequestPageContainer {
      max-width: 86%;
    }
  }
  
  @media (max-width: 277px) {
    .userRequestPageContainer {
      max-width: 84%;
    }
  }
  
  @media (max-width: 239px) {
    .userRequestPageContainer {
      max-width: 82%;
    }
  } */