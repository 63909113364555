.add-class-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 20px;
}

h1 {
  margin-bottom: 20px;
}

.class-button {
  background-color: #e0e0e0;
  color: #000;
  padding: 10px 20px;
  margin: 10px 0;
  border: 1px solid #ccc;
  cursor: pointer;
  border-radius: 5%;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  width: 200px; /* Ensure consistent button width */
}

.class-button:hover {
  background-color: #d0d0d0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  transform: translateY(-3px);
}

.class-button.selected {
  background-color: #4caf50;
  color: white;
}

.class-button.selected.delete {
  background-color: #ff4d4d; /* Red background for the selected delete button */
}

.or-divider {
  margin: 10px 0;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.5s ease-in-out, max-height 0.5s ease-in-out;
}

.input-container.show {
  opacity: 1;
  max-height: 100%;
}

.input-container input, .input-container select {
  margin-bottom: 10px;
  padding: 10px;
  width: 200px; /* Consistent width with buttons */
  border: 1px solid #000;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.add-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 200px; /* Ensure consistent button width */
}

.weekday-picker {
  margin-top: 10px;
  max-width: 100%; /* Ensures that the button group doesn't overflow */
}

.weekday-picker .btn-group {
  display: flex;
  flex-wrap: wrap; /* This will allow buttons to move to the next line */
  justify-content: center; /* This will center the buttons */
  gap: 5px; /* This adds a little space between the buttons */
}

.weekday-picker .btn {
  margin: 2px; /* Space between buttons */
  flex: 1 1 auto; /* Allows buttons to grow and shrink */
}

.capitalized {
  text-transform: capitalize;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 600px) {
  .weekday-picker .btn {
      padding: 5px; /* Smaller padding */
      font-size: 12px; /* Smaller font size */
  }
}

/* For small devices */
@media (max-width: 767px) {
  .weekday-picker .btn {
      padding: 0.25rem 0.5rem; /* Reduces padding */
      font-size: 0.875rem; /* Reduces font size */
      flex-grow: 1; /* Allows buttons to grow */
      flex-basis: calc(50% - 5px); /* Sets a base width with some space for gap */
  }
}

/* For extra small devices */
@media (max-width: 575px) {
  .weekday-picker .btn {
      flex-basis: calc(33% - 5px); /* Adjusts the base width for smaller devices */
  }
}
