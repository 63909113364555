/* Custom styles for the dialog box */
.dialog-content {
    max-width: 80vw; /* Default width */
    padding: 1rem; /* Default padding */
  }
  
  @media (max-width: 636px) {
    .dialog-content {
      max-width: 79vw; /* Slightly larger width for small screens */
      padding: 0.5rem; /* Reduce padding */
    }
  }
  