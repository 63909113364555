body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(../public/coach-mobile-box.png);
  background-repeat: no-repeat;
    background-position: center center;
    background-size: cover; /* this ensures the image covers the entire viewport */
    background-attachment: fixed; /* optional: this ensures the image stays put even when the content is scrolled */

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
