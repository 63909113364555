.toggle-tc {
    display: inline-block;
    position: relative;
    user-select: none;
    width: 90px;
    height: 34px;
    overflow: hidden;
}

.switch-tc {
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
}

.switch-tc input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider-tc {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: background-color 0.4s ease-in-out; /* Enhanced transition */
    border-radius: 34px;
}

.slider-tc:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    /* background-color: white; */
    background-color: red;
    transition: left 0.4s ease-in-out; /* Enhanced transition */
    border-radius: 50%;
}

input:checked + .slider-tc {
    /* background-color: #2196F3; */
    background-color: #ccc;
}

input:checked + .slider-tc:before {
    left: calc(100% - 30px);
    background-color: green;
}

.labels-container-tc {
    display: flex;
    position: absolute;
    left: -2px;
    right: 2px;
    top: 0;
    bottom: 0;
    width: 200%;
    transition: transform 0.4s ease-in-out; /* Enhanced transition */
}

.label-tc {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: white;
    width: 50%;
}

input:checked + .slider-tc .labels-container-tc {
    transform: translateX(-50%);
}
