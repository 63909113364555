.user-detail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* This makes sure the content is vertically centered in the viewport */
  padding: 20px;
}

.user-detail-card {
    width: 300px;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
margin-bottom: 20px;
  }
  
  .profile-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .profile-placeholder {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #ddd;
    margin-bottom: 10px;
  }
  
  .name {
    font-size: 20px;
    color: #333;
  }
  
  .admin-toggle {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  
  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  .status-label {
    margin-left: 10px;
    font-size: 16px;
    color: #666;
  }
  
.back-button {
  padding: 10px 20px;
  border: none;
  background-color: #2196F3;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

/* a media query that at screen width 379px user-detail-card width becomes 90% */
@media (max-width: 379px) {
  .user-detail-card {
    width: 90%;
  }
}
