/* styles/Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background-color: white !important;
    padding: 20px;
    border-radius: 10px !important;
    width: 90%;
    max-width: 500px;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    opacity: 1;
    margin: 0 auto; /* Center the modal horizontally */
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
}

@media (max-width: 640px) {
    .modal-content {
        width: 80% !important;
        padding: 15px;
        margin: 0 auto; /* Ensure centering on smaller screens */
        margin-right: 10px !important;
        left: 0;
        right: 0;
        position: absolute; /* Position absolutely within the overlay */
    }

    .modal-overlay {
        padding: 10px; /* Add some padding to the overlay */
    }
}

@media (max-width: 650px) {
    .modal-content {
        margin-right: 7%;
    }

    
}

@media (max-width: 340px) {
    .modal-content {
        margin-right: 1% !important;
    }

    
}