
.gym-logo-container {
    width: 100%;   /* The container takes up the full width */
    text-align: center;  /* All inline content, like our image, gets centered */
    z-index: 1000; /* Ensures the logo stays on top of other elements */
}
.gym-img-container {
    width: 150px;
    margin: auto;
}

.gym-logo {
    max-width: 150px; /* Adjust this to control the logo size */
    height: auto; 
    border-radius: 4px; /* Optional: adds rounded corners */
    /* display: inline-block;  Makes sure the image behaves like text and respects text-align */
}
