.cjc-toolbar {
    display: flex;
    flex-direction: column; /* stack children vertically */
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
}

.cjc-btn-group {
    display: flex; /* changed from inline-block to flex */
    justify-content: center; 
    white-space: nowrap;
    min-width: 250px; /* adjust based on your requirements */
    margin-bottom: 5px; /* spacing between the button group and the date label */
}

.cjc-btn-group > button:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.cjc-toolbar button:hover {
    color: #373a3c;
    background-color: #e6e6e6;
    border-color: #adadad;
}

.cjc-toolbar button {
    color: #373a3c;
    display: inline-block;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    background: none;
    background-image: none;
    border: 1px solid #ccc;
    padding: 0.375rem 1rem;
    border-radius: 4px;
    line-height: normal;
    white-space: nowrap;
}
