.feature-request-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 10px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  max-width: 100%; /* Ensure this is relative to its parent */
  overflow-x: hidden; /* Prevent horizontal overflow */
}



.feature-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.feature-info {
  display: flex;
  flex-direction: column;
}

.coach-name {
  color: #333;
  margin-bottom: 4px;
}

.feature-title {
  font-weight: normal;
  color: #333;
  margin-bottom: 0;
}

.feature-time {
  font-size: 0.9rem;
  color: #666;
}

.feature-body {
  font-weight: lighter;
  color: #666;
  overflow: hidden; /* Ensure the overflow content is not visible */
  white-space: nowrap; /* Keep the text on a single line */
  text-overflow: ellipsis; /* Add ellipsis at the end of the truncated text */
  max-width: 100%; /* Ensure this is set to limit the width of the content */
}

/* Media query for screens smaller than 859px */
@media (max-width: 859px) {
  .feature-request-card {
    /* Adjust padding or other properties as needed for smaller screens */
    padding: 12px;
  }

  .feature-body {
    /* Allow text to wrap on smaller screens instead of truncating */
    white-space: normal;
    text-overflow: clip;
  }

  /* You might want to adjust font sizes for smaller screens */
  .feature-time, .coach-name, .feature-title {
    font-size: 0.8rem;
  }
}
